import React, { useEffect, useState } from "react";
import ScannerService from "../../../services/barcode-scanne.service";
import { Link } from "react-router-dom";
import Dashboard from "../../components/Dashboard/dashboard";
import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  colors,
  Skeleton,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Badge,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { veterinaryDrugTypes } from "../../components/UnitsAndTypes/UnitsAndTypes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExportBarcodeButton from "../../components/xlsx/ExportBarcodeButton";
const ProductBarcodes = () => {
  const [products, setProducts] = useState({});
  const [filteredProducts, setFilteredProducts] = useState({});
  const [selectedBCodes, setSelectedBCodes] = useState([]);

  const [filters, setFilters] = useState({
    branch: "",
    productType: "",
    productName: "",
  });

  const [barcodes, setBarcodes] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const handleCardClick = (barcodeUrl) => {
    if (!barcodeUrl) return;
    setSelectedBCodes((prevSelected) =>
      prevSelected.includes(barcodeUrl)
        ? prevSelected.filter((url) => url !== barcodeUrl)
        : [...prevSelected, barcodeUrl]
    );
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await ScannerService.productBarcodeVt();
        if (response.ok) {
          const data = await response.json();
          setProducts(data.data);
          setFilteredProducts(data.data);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = {};

      Object.keys(products).forEach((branch) => {
        filtered[branch] = {};

        Object.keys(products[branch]).forEach((productType) => {
          filtered[branch][productType] = products[branch][productType].filter(
            (product) => {
              const branchFilterMatch =
                filters.branch === "" ||
                branch.toLowerCase().includes(filters.branch.toLowerCase());

              const productTypeFilterMatch =
                filters.productType === "" ||
                productType
                  .toLowerCase()
                  .includes(filters.productType.toLowerCase());

              const productNameFilterMatch =
                filters.productName === "" ||
                product.product_name
                  .toLowerCase()
                  .includes(filters.productName.toLowerCase());

              return (
                branchFilterMatch &&
                productTypeFilterMatch &&
                productNameFilterMatch
              );
            }
          );
        });
      });

      setFilteredProducts(filtered);
    };

    applyFilters();
  }, [filters, products]);

  useEffect(() => {
    const fetchBarcodes = async () => {
      const newBarcodes = {};
      for (const branch of Object.keys(filteredProducts)) {
        newBarcodes[branch] = {};
        for (const productType of Object.keys(filteredProducts[branch])) {
          newBarcodes[branch][productType] = {};
          for (const product of filteredProducts[branch][productType]) {
            const barcodeUrl = await getBarcodeSrc(product.product_id);
            newBarcodes[branch][productType][product.product_id] = barcodeUrl;
          }
        }
      }
      setBarcodes(newBarcodes);
    };

    fetchBarcodes();
  }, [filteredProducts]);

  const getBarcodeSrc = async (productId) => {
    try {
      const response = await ScannerService.genAndGetBarcodeVT(productId);
      if (response.ok) {
        const data = await response.blob();
        return URL.createObjectURL(data);
      } else {
        console.error(`Failed to get barcode for product ${productId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error getting barcode for product ${productId}:`, error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleDownload = (barcodeUrl) => {
    // const barcodeUrl = barcodes[branch]?.[productType]?.[productId];
    if (barcodeUrl) {
      const link = document.createElement("a");
      link.href = barcodeUrl;
      link.download = `${Date()}_barcode.png`; // Name the file based on the product ID
      link.click();
    }
  };
  return (
    <Dashboard>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" gap={2} marginBottom={4}>
          <TextField
            label="Filter by Branch"
            variant="outlined"
            name="branch"
            value={filters.branch}
            onChange={handleInputChange}
            InputProps={{
              style: { color: "white" },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
            }}
          />


     

          <TextField
            label="Filter by Product Name"
            variant="outlined"
            name="productName"
            value={filters.productName}
            onChange={handleInputChange}
            InputProps={{
              style: { color: "white" },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
            }}
          />

          <Select
            label="Product Type"
            name="productType"
            value={filters.productType}
            onChange={handleInputChange}
            displayEmpty
            sx={{
              color: "white", // Text color
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // Border color when not focused
                },
                "&:hover fieldset": {
                  borderColor: "white", // Border color when hovered
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // Border color when focused
                },
              },
            }}
          >
            <MenuItem value="">
              <em>All Types</em>
            </MenuItem>
            {veterinaryDrugTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
          <ExportBarcodeButton selectedBarcodes={selectedBCodes} />
        </Box>
        {filteredProducts.length === 0 && (
          <Typography variant="h5" gutterBottom sx={{ color: "white" }}>
            No product found
          </Typography>
        )}
        {Object.keys(filteredProducts).map((branch) => (
          <Box key={branch} marginBottom={6}>
            <Typography
              gutterBottom
              sx={{ color: "white", textAlign: "start" }}
            >
              Branch: {branch}
            </Typography>
            {Object.keys(filteredProducts[branch])
              .slice(0, showAll ? undefined : 1)
              .map((productType) => (
                <Box key={productType} marginBottom={4}>
                  <Grid container spacing={3}>
                    {filteredProducts[branch][productType].map((product) => (
                      <Grid item xs={12} sm={6} md={4} key={product.product_id}>
                        {!barcodes[branch]?.[productType] ? (
                          <Card
                            sx={{
                              backgroundColor: "#1F252B",
                              color: "white",
                              border: "3px solid transparent",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              height={100}
                              width="100%"
                              sx={{
                                bgcolor: "#7f7f7f",
                              }}
                            />

                            <CardContent>
                              <Box
                                display="flex"
                                flexDirection="row"
                                gap={2}
                                justifyContent={"space-between"}
                                alignItems="baseline"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={-2}
                                  justifyContent={"space-between"}
                                  alignItems="start"
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    height={12}
                                    width={"100%"}
                                    sx={{
                                      bgcolor: "#7f7f7f",
                                    }}
                                  />

                                  <Typography
                                    variant="subtitle1"
                                    textAlign="start"
                                    gutterBottom
                                    sx={{ color: "#1F252B" }}
                                  >
                                    ...................................
                                    <Skeleton
                                      variant="rectangular"
                                      height={12}
                                      width={"60%"}
                                      sx={{
                                        bgcolor: "#7f7f7f", // Change the skeleton color
                                      }}
                                    />
                                  </Typography>
                                </Box>

                                <IconButton
                                  aria-label="download barcode"
                                  disabled={
                                    !barcodes[branch]?.[productType]?.[
                                      product.product_id
                                    ]
                                  }
                                  color="primary"
                                  onClick={() =>
                                    handleDownload(
                                      barcodes[branch]?.[productType]?.[
                                        product.product_id
                                      ]
                                    )
                                  }
                                >
                                  <CloudDownloadIcon />
                                </IconButton>
                              </Box>
                            </CardContent>
                          </Card>
                        ) : (
                          <Badge
                            color="secondary"
                            badgeContent={
                              selectedBCodes.indexOf(
                                barcodes[branch]?.[productType]?.[
                                  product.product_id
                                ]
                              ) === -1
                                ? 0
                                : selectedBCodes.indexOf(
                                    barcodes[branch]?.[productType]?.[
                                      product.product_id
                                    ]
                                  ) + 1
                            }
                          >
                            <Card
                              onClick={() =>
                                handleCardClick(
                                  barcodes[branch]?.[productType]?.[
                                    product.product_id
                                  ] || ""
                                )
                              }
                              sx={{
                                backgroundColor: "#1F252B", // Set card background to black
                                color: "white", // Ensure text inside the card is visible
                                border: selectedBCodes.includes(
                                  barcodes[branch]?.[productType]?.[
                                    product.product_id
                                  ] || ""
                                )
                                  ? "3px solid green"
                                  : "3px solid transparent",

                                cursor: barcodes[branch]?.[productType]?.[
                                  product.product_id
                                ]
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                            >
                              {!barcodes[branch]?.[productType]?.[
                                product.product_id
                              ] ? (
                                <Skeleton
                                  variant="rectangular"
                                  height={100}
                                  width="100%"
                                  sx={{
                                    bgcolor: "#7f7f7f", // Change the skeleton color
                                  }}
                                />
                              ) : (
                                <CardMedia
                                  component="img"
                                  image={
                                    barcodes[branch]?.[productType]?.[
                                      product.product_id
                                    ] || ""
                                  }
                                  alt={`Barcode for ${product.product_name}`}
                                  sx={{
                                    height: "100px",
                                    width: "auto",
                                    maxWidth: "100%",
                                    bgcolor: "white",

                                    p: 1,
                                  }}
                                />
                              )}
                              <CardContent>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap={2}
                                  justifyContent={"space-between"}
                                  alignItems="baseline"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={-2}
                                    justifyContent={"space-between"}
                                    alignItems="start"
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      textAlign="start"
                                      gutterBottom
                                      sx={{ fontWeight: 600 }}
                                    >
                                      {product.product_name}
                                    </Typography>
                                    <Typography
                                      variant="subtitle1"
                                      textAlign="start"
                                      gutterBottom
                                      sx={{ fontWeight: 300 }}
                                    >
                                      {productType}
                                    </Typography>
                                  </Box>

                                  <IconButton
                                    aria-label="download barcode"
                                    disabled={
                                      !barcodes[branch]?.[productType]?.[
                                        product.product_id
                                      ]
                                    }
                                    color="primary"
                                    onClick={() =>
                                      handleDownload(
                                        barcodes[branch]?.[productType]?.[
                                          product.product_id
                                        ]
                                      )
                                    }
                                  >
                                    <CloudDownloadIcon />
                                  </IconButton>
                                </Box>
                              </CardContent>
                            </Card>
                          </Badge>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
          </Box>
        ))}

        <Box textAlign="center" marginTop={4}>
          <Button variant="contained" color="secondary" onClick={toggleShowAll}>
            {showAll ? "Show Less" : "Show All"}
          </Button>
        </Box>
      </Container>
    </Dashboard>
  );
};
export default ProductBarcodes;
