import React, { useState, useRef } from "react";
import LockClockIcon from "@mui/icons-material/LockClock";
import loginService from "../../../services/login.service";
import { useAuth } from "../../../Context/AuthContext";

const VerifyOTP = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpError, setOtpError] = useState("");
  const [serverError, setServerError] = useState("");
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { employee } = useAuth();

  // Retrieve employeeId from localStorage
  const employeeId = JSON.parse(
    localStorage.getItem("employeeID")
  )?.employee_id;

  const handleInputChange = (index, value) => {
    setOtpError(""); // Clear previous error

    if (!/^\d?$/.test(value)) return; // Allow only single digit (numbers)

    // Update the OTP state
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus on the next input if a digit is entered
    if (value && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }

    // Move to the previous input if backspace is pressed and current input is empty
    if (value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const enteredOtp = otp.join("");

    if (enteredOtp.length < 6) {
      setOtpError("Please enter a 6-digit OTP");
      return;
    }

    setIsSubmitting(true);
    try {
      // Call the service to verify OTP
      const response = await loginService.verifyOTP(employeeId, enteredOtp);

      if (response.status === "success") {
        if (response.data.employee_token) {
          localStorage.setItem("employee", JSON.stringify(response.data));
        }

        window.location.href = "/dashboard/employee/personal/account";

        // Remove employeeId from localStorage
        localStorage.removeItem("employeeID");
        localStorage.removeItem("authMessage");
      } else {
        setServerError(response.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setServerError("An error has occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); // End submitting
    }
  };

  return (
    <div className="input-field otp-container m-4">
      <div className="otp-form-container">
        <header className="mb-4 text-center">
          <LockClockIcon className="otp-icon" />
        </header>
        <h4>Let’s confirm it’s you!</h4>
        <p className="otp-inform">
          Verify using the one-time password sent to your email or contect the
          system administrator for assistance in retrieving the code. Please
          note that the code is valid for only 6 minutes.
        </p>
        {serverError && <p className="text-danger">{serverError}</p>}
        {otpError && <div className="text-danger">{otpError}</div>}
        <form onSubmit={handleSubmit} className="otp-form">
          <div className="otp-input-field">
            {otp.map((digit, index) => (
              <input
                key={index}
                className={`me-2 otp-input ${otpError ? "otp-err" : ""}`}
                type="text"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                ref={inputRefs.current[index]}
                maxLength="1"
              />
            ))}
          </div>
          <div className="">
            <button
              id="button_"
              type="submit"
              className={isSubmitting ? "disabledButton" : ""}
              disabled={isSubmitting}
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOTP;
