import React, { useState, useEffect } from "react";
import { getCurrentDate } from "../../../../util/currentdate";
import {
  useAgroShowroomProducts,
  useVetShowroomProducts,
  useVetSoldProducts,
  useAgroSoldProducts,
  useVetStoreAvailableProducts,
  useAgroAvailableStoreProducts,
  useAgroOutedProducts,
  useVetOutedProducts,
} from "../../../components/APIs/FetchAPIs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Excelicon from "../../../../assets/images/Excel-icon.png";

const useBackupData = () => {
  const { agroproducts } = useAgroShowroomProducts();
  const { vetproducts } = useVetShowroomProducts();
  const { vetsoldProducts } = useVetSoldProducts();
  const { agrosoldProducts } = useAgroSoldProducts();
  const { vetStoreAvailableProduct } = useVetStoreAvailableProducts();
  const { agroStoreAvailableProduct } = useAgroAvailableStoreProducts();
  const { agrooutedProducts } = useAgroOutedProducts();
  const { vetoutedProducts } = useVetOutedProducts();

  const currentDate = getCurrentDate();
  const [backupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setBackupData([
      {
        type: "agro_showroom",
        fileName: "agro_showroom_products",
        data: agroproducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "vet_showroom",
        fileName: "vet_showroom_products",
        data: vetproducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "vet_sold",
        fileName: "vet_sold_products",
        data: vetsoldProducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "agro_sold",
        fileName: "agro_sold_products",
        data: agrosoldProducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "agro_store",
        fileName: "agro_store_available_product",
        data: agroStoreAvailableProduct,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "vet_store",
        fileName: "vet_store_available_product",
        data: vetStoreAvailableProduct,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "agro_oute",
        fileName: "agro_outed_products",
        data: agrooutedProducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
      {
        type: "vet_oute",
        fileName: "vet_outed_products",
        data: vetoutedProducts,
        date: currentDate,
        icon: Excelicon,
        size: "10KB",
      },
    ]);
    setLoading(false);
  }, [
    agroproducts,
    vetproducts,
    vetsoldProducts,
    agrosoldProducts,
    vetStoreAvailableProduct,
    agroStoreAvailableProduct,
    agrooutedProducts,
    vetoutedProducts,
    currentDate,
  ]);

  const handleExport = ({ fileName, data }) => {
  
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(blob, fileName);

      const fileSizeInBytes = excelBuffer.length;
      const fileSizeInKB = fileSizeInBytes / 1024;

      return fileSizeInKB;
    };

    // Call exportToExcel directly when needed
    exportToExcel();

    return null;
  };

  return { backupData, handleExport, loading, error };
};

export default useBackupData;
