import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../components/Dashboard/dashboard";
import "./ai-assistant.css";
import aiassistant from "../../../assets/images/ai-chat--removebg-preview.png";
import { useAuth } from "../../../Context/AuthContext";
import { Link } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { useLocation } from "react-router-dom";
import AIAssistantService from "../../../services/ai-assistant.service";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ReplayIcon from "@mui/icons-material/Replay";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typewriter from "../../../util/Typewriter";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
function AIAssistant() {
  const { employee } = useAuth();
  const [showHistory, setShowHistory] = useState(false);
  const EmployeeID = localStorage.getItem("EmployeeID");
  const [responseMessage, setResponseMessage] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [generatedtext, setGeneratedtext] = useState([]);
  const [apiError, setApiError] = useState();
  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function getConvIdFromUrl() {
    const pathSegments = window.location.pathname.split("/");
    return pathSegments.pop();
  }
  const ConvID = getConvIdFromUrl();
  const [prompt, setPrompt] = useState({
    message: "",
    employeeId: EmployeeID,
    conversationId: !ConvID ? "" : ConvID,
  });

  useEffect(() => {
    setPrompt((prevPrompt) => ({
      ...prevPrompt,
      conversationId: ConvID,
    }));
  }, [ConvID]);
  const [copied, setCopied] = useState(false);
  const [err, setErr] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.message.trim()) {
      alert("Please enter your ask first");
      return;
    }
    try {
      const response = await AIAssistantService.sendMessage(prompt);
      setPrompt({ ...prompt, message: "" });
      setResponseMessage(
        Array.isArray(response.response)
          ? response.response
          : [response.response]
      );
      if (response.ConvId && !location.pathname.includes(response.ConvId)) {
        const currentPath = location.pathname;
        const newPath = `${currentPath}${response.ConvId}`;
        window.history.replaceState({}, "", newPath);
      } else {
        console.error("ConvId not found in response");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setApiError(error);
      setTimeout(() => {
        setApiError(null);
      }, 6000);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const fetchConversationByEmployeeId = async () => {
      try {
        const response = await AIAssistantService.getConversationByEmployeeId(
          EmployeeID
        );

        if (response.status === "success") {
          setConversation(response.data);
        } else {
          console.error(`Error fetching Prompts: ${response.status}`);
          setErr(`Error fetching Prompts: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching Prompts:", error.message);
        setErr(`Error fetching Prompts: ${error.message}`);
      }
    };

    if (EmployeeID) {
      fetchConversationByEmployeeId();

      const intervalId = setInterval(fetchConversationByEmployeeId, 1000);

      return () => clearInterval(intervalId);
    }
  }, [EmployeeID]);

  useEffect(() => {
    const getPromptByConversationID = async () => {
      try {
        const response = await AIAssistantService.getPromptByConversationID(
          ConvID
        );

        if (response.status === "success") {
          setPrompts(response.data);
        } else {
          console.error(`Error fetching Prompts: ${response.status}`);
          setErr(`Error fetching Prompts: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching Prompts:", error.message);
        setErr(`Error fetching Prompts: ${error.message}`);
      }
    };
    if (ConvID) {
      getPromptByConversationID();

      const intervalId = setInterval(getPromptByConversationID, 1000);

      return () => clearInterval(intervalId);
    }
  }, [ConvID]);

  useEffect(() => {
    const getGeneratedTextByConversationID = async () => {
      setIsLoading(true);
      try {
        const response =
          await AIAssistantService.getGeneratedTextByConversationID(ConvID);

        if (response.status === "success") {
          setGeneratedtext(response.data);
        } else {
          console.error(`Error: ${response.status}`);
          setErr(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error.message);
        setErr(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (ConvID) {
      getGeneratedTextByConversationID();

      const intervalId = setInterval(getGeneratedTextByConversationID, 2000);

      return () => clearInterval(intervalId);
    }
  }, [ConvID]);

  const handleReplayClick = () => {
    if (responseMessage.length > 0) {
      const firstMessage = responseMessage[0];
      setPrompt({ message: firstMessage });
      handleSubmit(new Event("submit"));
    }
  };
  const containerRef = useRef(null);
  const handleCopyClick = () => {
    const responseText = responseMessage.join("\n");
    navigator.clipboard
      .writeText(responseText)
      .then(() => {
        // console.log("Response text copied:", responseText);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy response text:", err);
      });
  };

  const handleCardTextClick = (text) => {
    handleCopyClick(text);
    setClickCount((prevCount) => prevCount + 1);
    if (clickCount % 4 === 0) {
      setPrompt({ message: text });
      handleSubmit(new Event("submit"));
    }
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  const handeleNewChat = () => {
    window.location.href = "/dashboard/ai-assistant/";
  };
  const handleDelete = async (ConversationId) => {
    if (
      window.confirm("Are you sure you want to delete this conversationId?")
    ) {
      try {
        const response = await AIAssistantService.deleteConversation(
          ConversationId
        );
        if (response.ok) {
          setConversation((prevdata) =>
            prevdata.filter(
              (conversation) => conversation.conversation_id !== ConversationId
            )
          );
          alert("Conversation deleted successfully");
        } else {
          console.error(`Error deleting branch: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting branch:", error);
      }
    }
  };
  function isScrolledToTop(container) {
    // Check if the container exists
    if (!container) return false;

    // Check if the scroll position is at the top
    return container.scrollTop === 0;
  }

  // Assuming you have a state variable called isAtTop and a function setIsAtTop to update it
  const [isAtTop, setIsAtTop] = useState(false);

  // Assuming containerRef is your container reference
  const handleScroll = () => {
    setIsAtTop(isScrolledToTop(containerRef.current));
  };

  // Add an event listener to the container to track scroll events
  useEffect(() => {
    const container = containerRef.current;

    // Check if the container exists
    if (!container) return;

    // Add event listener for scroll events
    container.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current]);

  return (
    <Dashboard>
      <HistoryIcon
        className="text-info text-end justify-content-end align-self-end fs-4"
        onClick={() => setShowHistory(!showHistory)}
      />
      <div className="ai-contaner text-white d-flex flex-column justify-content-center align-items-center">
        <div
          className={`list-group left-side chat-ai-sidebar text-start bg-custom-d- rounded-0 ${
            showHistory && "open_ sidebar_"
          }`}
          style={{ overflowY: "auto" }}
        >
          <h4 className="mb-4 text-white text-center mt-3 ">
            Historys{" "}
            <HistoryIcon
              className="text-white text-start fs-4"
              onClick={() => setShowHistory(!showHistory)}
            />
          </h4>
          <ol className="list-group">
            <li className="list-group-item custom-list border-bottom border-white mt-2 rounded-0">
              <Link
                onClick={handeleNewChat}
                className="btn btn-md w-100 btn-outline-info"
              >
                New Chat 
              </Link>
            </li>

            {conversation.map((conversationItem, index) => (
              <Link
                to={`/dashboard/ai-assistant/${conversationItem.conversation_id}`}
                className="text-decoration-none justify-content-between d-flex align-items-center border-bottom border-white"
              >
                <li
                  key={index}
                  className="list-group-item custom-list  mt-2 rounded-0"
                >
                  {conversationItem.title}
                </li>{" "}
                <Link
                  onClick={() => handleDelete(conversationItem.conversation_id)}
                  className="text-danger "
                >
                  <DeleteOutlinedIcon />
                </Link>
              </Link>
            ))}
          </ol>
        </div>
        <div
          className={`container ${
            prompts.length !== 0 ? "cntdiv" : " "
          } align-items-center justify-content-center`}
          ref={containerRef}
        >
          <>
            {prompts.map((prompt, index) => (
              <div className="prompt-container " key={index}>
                <div className="card bg-custom-d- text-white border-dark pt-container">
                  <div className="response-card-body text-start border-dark  ">
                    <div className="fs-2 mt-2  me-5 align-self-start  d-flex text-start">
                      <AccountCircleIcon className="me-1 fs-2" />
                      <i className="text-start fs-5 ">
                        <span className="text-white">You</span>{" "}
                      </i>
                    </div>
                    <p className="text-white text-start ">{prompt.prompt}</p>
                  </div>
                </div>
                <div className="response-container">
                  {generatedtext[index] && (
                    <div
                      key={index}
                      className="card bg-custom-d- text-white border-dark"
                    >
                      <div className="response-card-body text-start border-dark">
                        <div className="fs-2 mb-2 mt-2  d-flex">
                          <img
                            src={aiassistant}
                            width="30"
                            className="border-5 border-danger me-1"
                          />
                          <i className="text-start fs-5 ">
                            <span className="AgriMentor">AgriMentor</span>{" "}
                          </i>
                        </div>
                        <div>
                          <p className="">
                            <Typewriter
                              text={generatedtext[index].text}
                              delay={10}
                              infinite={false}
                            />
                          </p>
                        </div>
                        <div className="row">
                          <div>
                            {!copied ? (
                              <ContentPasteIcon
                                className="me-2 fs-5"
                                onClick={handleCopyClick}
                              />
                            ) : (
                              <span className="text-success me-2">Copied!</span>
                            )}{" "}
                          </div>
                          <div>
                            <ReplayIcon
                              className="fs-5"
                              onClick={handleReplayClick}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isAtTop ? <> </> : <div ref={messagesEndRef} />}
                </div>
              </div>
            ))}
          </>
        </div>

        {err && (
          <div className="validation-error" role="alert">
            {err}
          </div>
        )}
        {prompts.length === 0 && (
          <>
            <div className="head-container">
              <h1 className="text-start">
                {" "}
                <span>Hello, {employee?.employee_first_name}</span>
                <h2 className="text-white">
                  Greetings from{" "}
                  <span className="text-decoration-underline">AgriMentor,</span>{" "}
                  Your AI assistant.
                </h2>
                <h2 className="text-white">How can I help you today?</h2>
              </h1>
            </div>
            <div className="row text-start text-white">
              <div className="col-sm-6 mt-2">
                <div className="card custom-card-style bg-custom-d- text-white">
                  <div className="card-body cb-c">
                    <h6 className="card-title">
                      Maximizing Yield, Minimizing Impact:
                    </h6>
                    <Link
                      className="card-text text-white"
                      onClick={() =>
                        handleCardTextClick(
                          `How can I boost crop yields while minimizing environmental harm?`
                        )
                      }
                    >
                      How can I boost crop yields while minimizing environmental
                      harm?
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mt-2">
                <div className="card custom-card-style bg-custom-d- text-white">
                  <div className="card-body cb-c">
                    <h6 className="card-title">Sustainability in Action:</h6>
                    <Link
                      className="card-text text-white"
                      onClick={() =>
                        handleCardTextClick(
                          "What are today's best sustainable farming practices?"
                        )
                      }
                    >
                      What are today's best sustainable farming practices?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-start text-white">
              <div className="col-sm-6 mt-2">
                <div className="card custom-card-style bg-custom-d- text-white">
                  <div className="card-body cb-c">
                    <h6 className="card-title">Tech-Forward Farming:</h6>
                    <Link
                      className="card-text text-white"
                      onClick={() =>
                        handleCardTextClick(
                          "How can technology boost agricultural productivity?"
                        )
                      }
                    >
                      How can technology boost agricultural productivity?
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mt-2">
                <div className="card custom-card-style bg-custom-d- text-white">
                  <div className="card-body cb-c ">
                    <h6 className="card-title">Nutritional Guidance</h6>
                    <Link
                      className="card-text text-white"
                      onClick={() =>
                        handleCardTextClick(
                          `What's the role of nutrition in animal health, and how do
                      vets offer optimal guidance?`
                        )
                      }
                    >
                      What's the role of nutrition in animal health, and how do
                      vets offer optimal guidance?
                    </Link>
                  </div>
                </div>
              </div>{" "}
            </div>
          </>
        )}
        {apiError && (
          <div className="alert-danger" role="alert">
            {apiError.message} {/* or any other relevant property */}
          </div>
        )}

        <div className="messageBox m-3">
          <form onSubmit={handleSubmit} className="w-100 aichat-form d-flex">
            <input
              className="w-100"
              placeholder="Ask me anything..."
              id="messageInput"
              autoComplete="off"
              type="text"
              value={prompt.message}
              onChange={(e) =>
                setPrompt({ ...prompt, message: e.target.value })
              }
            />

            <button type="submit" id="sendButton">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 664 663"
              >
                <path
                  fill="none"
                  d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                ></path>
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="33.67"
                  stroke="#6c6c6c"
                  d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </Dashboard>
  );
}

export default AIAssistant;
