import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ReplyIcon from "@mui/icons-material/Reply";
import EditSoldProduct from "./EditSoldProduct";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import SyncIcon from "@mui/icons-material/Sync";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import {
  calculateCurrentMonthRevenue,
  calculateTodayRevenue,
  calculateTotalProductQuantity,
  calculateTotalRevenue,
} from "../Functions/CalculateFunctions";
import AgroSoldProductTypePieChart from "../Charts/PieCharts/ProductTypePieChart";
import AgroWeeklySalesLineChart from "../Charts/SingleLineCharts/WeeklySalesLineChart";
import FilterListCard from "../Products/FilterListCard";
import AddIcon from "@mui/icons-material/Add";

function StoreOutedProducts({
  handleDeleteProduct,
  products,
  OutedProducts,
  isLoading,
  successMessage,
  HandleEdit,
  service,
  branchs,
  servicetype,
  handleFetchData,
}) {
  const [showAll, setShowAll] = useState(false);
  const [Error, setError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [productUnitFilter, setProductUnitFilter] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [showFilterCard, setShowFilterCard] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDates, setShowDates] = useState(false);
  const [showFullNames, setshowFullNames] = useState(false);
  const handleShowFullNames = () => {
    setshowFullNames(!showFullNames);
  };
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  const handleToggleFilterCard = () => {
    setShowFilterCard(!showFilterCard);
  };
  const [editingProduct, setEditingProduct] = useState(null);

  const handleEdit = (OutedProducts) => {
    setEditingProduct(OutedProducts);
  };
  const handleCancelEdit = () => {
    setEditingProduct(null);
  };
  const handleFilterByProductType = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductTypeFilter(selectedOption.value);
    } else {
      // If selectedOption is null or doesn't have a value, clear the filter
      setProductTypeFilter("");
    }
  };

  const handleFilterByProductUnit = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductUnitFilter(selectedOption.value);
    } else {
      // If selectedOption is null or doesn't have a value, clear the filter
      setProductUnitFilter("");
    }
  };

  const handleFilterByBranch = (e) => {
    setBranchFilter(e.target.value);
  };

  const handleSetStartDate = (date) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setEndDate(date);
  };
  const handleSetAdjustmentId = (ProductId) => {
    localStorage.setItem("adjustment", ProductId);
  };
  const filteredproduct = showAll
    ? OutedProducts
    : OutedProducts.filter((OutedProducts) => {
        const fieldsToSearch = [
          "product_id",
          "product_name",
          "product_unit_price",
        ];

        const soldDate = new Date(OutedProducts.outed_date);

        return (
          (!startDate || soldDate >= startDate) &&
          (!endDate || soldDate <= endDate) &&
          fieldsToSearch.some((field) =>
            String(OutedProducts[field])
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ) &&
          (productTypeFilter === "" ||
            OutedProducts.product_type.toLowerCase() ===
              productTypeFilter.toLowerCase()) &&
          (productUnitFilter === "" ||
            OutedProducts.product_unit.toLowerCase() ===
              productUnitFilter.toLowerCase()) &&
          (branchFilter === "" ||
            OutedProducts.branch.toLowerCase() === branchFilter.toLowerCase())
        );
      }).slice(0, 500);
  const clearFilters = () => {
    setBranchFilter("");
    setProductUnitFilter("");
    setProductTypeFilter("");
    setStartDate(null);
    setEndDate(null);
    setshowFullNames(false);
    setShowDates(false);
  };
  // Calculate today's revenue for the filtered products
  const todayRevenue = calculateTodayRevenue(
    filteredproduct,
    "outed_date",
    "product_total_price"
  );

  // Calculate total revenue for the filtered products
  const totalRevenue = calculateTotalRevenue(
    filteredproduct,
    "product_total_price"
  );

  // Calculate current month's revenue for the filtered products
  const currentMonthRevenue = calculateCurrentMonthRevenue(
    filteredproduct,
    "outed_date",
    "product_total_price"
  );

  // Calculate total quantity for the filtered products
  const totalProductQuantity = calculateTotalProductQuantity(
    filteredproduct,
    "product_quantity"
  );
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <Dashboard>
      {editingProduct ? (
        <EditSoldProduct
          ProductId={editingProduct.product_id}
          onCancel={handleCancelEdit}
          editingProduct={editingProduct}
          products={products}
          handleEdit={HandleEdit}
          service={service}
          branchs={branchs}
        />
      ) : (
        <>
          <div className="container-fluid pt-4 px-4 text-white">
            {Error && <div className="alert alert-danger">{Error}</div>}
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}

            <div className="row g-4">
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-3 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <StackedLineChartIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Today Outed</p>
                    <h6 className="mb-0">ETB {todayRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-10 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <MonetizationOnIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Month's Outed</p>
                    <h6 className="mb-0">
                      ETB {currentMonthRevenue.toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-4 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <LeaderboardIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Qty</p>
                    <h6 className="mb-0">QTY {totalProductQuantity}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="ovr-5 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <PieChartIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Outed</p>
                    <h6 className="mb-0">ETB {totalRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid  pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-black text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0 text-info ">Weekly Outed</h6>
                    <Link className="btn btn-sm btn-outline-info">
                      Show All
                    </Link>
                  </div>
                  <AgroWeeklySalesLineChart
                    OutedProducts={filteredproduct}
                    type={"store"}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bgsecondary text-center rounded p-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6 className="mb-0 text-success">Outed Product Types</h6>
                    <Link className="btn btn-sm btn-outline-danger">
                      Show All
                    </Link>
                  </div>
                  <AgroSoldProductTypePieChart products={filteredproduct} />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4 mb-5">
            <div className="s-outed-con text-center rounded p-4">
              <div className="d-lg-flex d-md-flex  d-sm-inline-block d-sm-block   align-items-center justify-content-between mb-4">
                <div className="form-floating  ">
                  <input
                    type="search"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Search Products"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                  <label htmlFor="floatingInput">Search Products</label>
                </div>

                <FilterListCard
                  showFilterCard={showFilterCard}
                  handleToggleFilterCard={handleToggleFilterCard}
                  handleFilterByBranch={handleFilterByBranch}
                  handleFilterByProductUnit={handleFilterByProductUnit}
                  handleFilterByProductType={handleFilterByProductType}
                  branchFilter={branchFilter}
                  productUnitFilter={productUnitFilter}
                  productTypeFilter={productTypeFilter}
                  handleSetStartDate={handleSetStartDate}
                  handleSetEndDate={handleSetEndDate}
                  service={service}
                />

                <Link
                  className="xpm btn btn-sm btn-outline-danger"
                  onClick={handleShowAll}
                >
                  {showAll ? "Show Less" : "Show All"}
                </Link>
              </div>
              <div className="table-responsive">
                <div className="d-flex mt-0 ">
                  <h6 className="text-white text-start mb-2 me-2">
                    Recent Outed
                  </h6>
                  <Link
                    className="xpm me-2 btn btn-sm btn-outline-warning "
                    onClick={handleShowDates}
                  >
                    {showDates ? "Hide dates" : "Show dates"}
                  </Link>
                  <button
                    className="btn btn-outline-info  btn-sm me-2"
                    onClick={handleToggleFilterCard}
                  >
                    Filters
                  </button>
                  <button
                    className="btn-ccgray text-white btn-sm me-2"
                    onClick={handleShowFullNames}
                  >
                    {showFullNames ? "Hide Full Names" : "Show Full Names"}
                  </button>
                  <button
                    className="btn btn-outline-danger  btn-sm me-2"
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </button>
                  <Link
                    className="btn btn-success btn-sm me-2 justify-self-end"
                    to={
                      servicetype === "vetstore"
                        ? `/dashboard/store/vet/out-products`
                        : `/dashboard/store/agro/out-products`
                    }
                  >
                    <AddIcon className="" />
                  </Link>
                  {isLoading ? (
                    <SyncIcon className="fetchiconOnloading ms-2" />
                  ) : (
                    <CloudSyncIcon
                      onClick={handleFetchData}
                      className="refetchdataicon ms-2"
                    />
                  )}
                </div>
                <table className="table text-start align-middle table-bordered table-striped table-hover mt-1 table-dark mb-0">
                  <thead>
                    <tr className="text-white">
                      <th scope="col">
                        <input className="form-check-input" type="checkbox" />
                      </th>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Unit</th>
                      <th scope="col">QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                      <th scope="col">To</th>
                      <th scope="col">Outed at</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!filteredproduct.length && isLoading ? (
                      <tr>
                        <td colSpan="12" className="text-center">
                          <span className="loader"></span>
                        </td>
                      </tr>
                    ) : (
                      filteredproduct.map((OutedProducts) => (
                        <tr key={OutedProducts.product_id}>
                          <td>
                            <input
                              className="form-check-input bg-custom-d- "
                              type="checkbox"
                            />
                          </td>
                          <th scope="row">{OutedProducts.product_id}</th>
                          <td className={`${showFullNames ? "" : "clip-text"}`}>
                            {OutedProducts.product_name}
                          </td>
                          <td>{OutedProducts.product_type}</td>
                          <td>{OutedProducts.product_unit}</td>
                          <td>{OutedProducts.product_quantity}</td>
                          <td>{OutedProducts.product_unit_price}</td>
                          <td>{OutedProducts.product_total_price}</td>

                          <td>{OutedProducts.branch}</td>
                          {showDates ? (
                            <td>
                              <span className="outed_date-format">
                                {format(
                                  parseISO(OutedProducts.outed_date),
                                  "MM/dd/yyyy HH:mm:ss"
                                )}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className="outed_date">
                                {formatDistanceToNow(
                                  parseISO(OutedProducts.outed_date),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </span>
                            </td>
                          )}
                          <td className="d-flex">
                            <Link
                              Link
                              className="btn btn-sm btn-success  me-2"
                              onClick={() => handleEdit(OutedProducts)}
                            >
                              Edit
                            </Link>
                            <Link
                              className="btn-sm btn btn-danger me-3"
                              onClick={() =>
                                handleDeleteProduct(OutedProducts.product_id)
                              }
                            >
                              Delete
                            </Link>
                            <Link
                              className="sharecon rounded-1 "
                              onClick={() =>
                                handleSetAdjustmentId(products.product_id)
                              }
                              to="/dashboard/help-request"
                            >
                              <ReplyIcon className="Sicon" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default StoreOutedProducts;
