import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard/dashboard";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import VerifiedIcon from "@mui/icons-material/Verified";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import {
  Badge,
  Box,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
const StoreTruthTrack = ({
  initialBalanceData,
  handleAddInitialBalancs,
  Error,
  SuccessMessage,
  data,
}) => {
  const [Deposit, setDeposit] = useState(false);
  const { employee, isAdmin } = useAuth();
  const [Newbalance, setNewbalance] = useState(null);
  const handleDeposit = (balance) => {
    setNewbalance(balance);
    setDeposit(!Deposit);
  };
  const [updatedBalanceData, setupdatedBalanceData] = useState({
    amount: "",
    ...Newbalance,
  });

  const calculatedBalance =
    data?.why?.initialBalance +
    data?.why?.stockINBalance -
    data?.why?.stockOutBalance;

  const noData =
    !data || data?.why?.currentStockBalance === 0 || !data?.why?.initialBalance;
  // console.log(data);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedBalanceData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <Dashboard>
      {noData ? (
        <Box
          sx={{
            height: "70vh",
          }}
        >
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
              mt: 3,
            }}
          >
            <Box
              sx={{
                flex: 1.2,
                height: "100%",
                mr: { lg: 2, xs: 0 },
                mb: { xs: 3, lg: 0 },
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 7,
                  border: 2,
                  borderColor:
                    data.verificationMessage !== "Verified" ? "red" : "green",
                  p: 4,
                  pb: 5,
                  backgroundColor:
                    data.verificationMessage !== "Verified"
                      ? "#f8d7da"
                      : "#d4edda",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 4, color: "warning.main", textAlign: "start" }}
                >
                  Your Status
                </Typography>
                {data.verificationMessage !== "Verified" ? (
                  <NewReleasesIcon
                    sx={{ fontSize: 60, color: "warning.main" }}
                  />
                ) : (
                  <VerifiedIcon sx={{ fontSize: 60, color: "success.main" }} />
                )}
                <Typography variant="h4" sx={{ mt: 2, letterSpacing: 1 }}>
                  {data.verificationMessage}
                </Typography>

                <Typography
                  variant="body2"
                  align="center"
                  color={
                    data.verificationMessage === "Verified" ? "green" : "red"
                  }
                  sx={{ mt: 1 }}
                >
                  {data.verificationMessage === "Verified" ? (
                    "Congratulations! Your account is verified, and you can proceed safely."
                  ) : (
                    <>
                      Your account is not verified. Please contact the
                      administrator to correct this issue and do not proceed
                      with this status.
                    </>
                  )}
                </Typography>
              </Paper>
            </Box>

            <Box
              sx={{
                flex: 2,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  backgroundColor: "#1F252B",
                  color: "white",
                  border: 1,
                  borderRadius: 7,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 4, color: "warning.main", textAlign: "start" }}
                >
                  Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Current Stock Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Chip
                      label={
                        <NumericFormat
                          value={data?.why?.currentStockBalance}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                      }
                      sx={{ color: "#fff", fontWeight: "bold" }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Initial Stock Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Chip
                      label={
                        <NumericFormat
                          value={data?.why?.initialBalance}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                      }
                      sx={{ color: "#fff", fontWeight: "bold" }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Stock IN Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Chip
                      label={
                        <NumericFormat
                          value={data?.why?.stockINBalance}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                      }
                      sx={{ color: "#fff", fontWeight: "bold" }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Stock Out Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Chip
                      label={
                        <NumericFormat
                          value={data?.why?.stockOutBalance}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                      }
                      sx={{ color: "#fff", fontWeight: "bold" }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Box>

          <div className="container mt-2">
            {/* Error Message */}
            {Error && (
              <div
                className="alert alert-danger text-center d-flex align-items-center justify-content-center"
                role="alert"
              >
                <HighlightOffIcon className="me-3 errricon" />
                <div className="text-center erri">{Error}</div>
              </div>
            )}

            {/* Success Message */}
            {SuccessMessage && (
              <div
                className="alert alert-success d-flex align-items-center"
                role="alert"
              >
                <CheckCircleIcon className="bi flex-shrink-0 me-2" />
                <div>{SuccessMessage}</div>
              </div>
            )}

            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-md-6 mb-4">
                <div className="gradient-border-pseudo-- p-4">
                  {initialBalanceData.map((balance, index) => (
                    <div key={index} className="card-body">
                      <h3>
                        <NumericFormat
                          value={balance.amount}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                      </h3>
                      <p>
                        (Initial Balance:{" "}
                        <NumericFormat
                          value={balance.amount}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="ETB "
                          decimalScale={2}
                        />
                        )
                      </p>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <Link
                            className="btn btn-md btn-outline-light"
                            onClick={() => handleDeposit(balance)}
                          >
                            Add Initial Balance
                          </Link>
                          {Deposit && (
                            <form
                              onSubmit={(event) =>
                                handleAddInitialBalancs(
                                  event,
                                  Newbalance.balance_id,
                                  updatedBalanceData
                                )
                              }
                            >
                              <input
                                type="number"
                                className="form-control mt-2"
                                id="paymentInput0"
                                placeholder="Amount"
                                name="amount"
                                value={updatedBalanceData.amount}
                                onChange={handleInputChange}
                              />
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                                disabled={!isAdmin}
                              >
                                Add{" "}
                                <NumericFormat
                                  value={updatedBalanceData.amount}
                                  displayType="text"
                                  thousandSeparator={true}
                                  prefix="ETB "
                                  decimalScale={2}
                                />
                              </button>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  mx: "auto",
                  mb: 4,
                  p: 3,

                  backgroundColor: "#1F252B",
                  color: "white",
                  border: 1,
                  borderRadius: 7,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 4, color: "warning.main", textAlign: "start" }}
                >
                  Balance Difference
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color:
                        calculatedBalance < 0 ? "error.main" : "success.main",
                    }}
                  >
                    <NumericFormat
                      value={calculatedBalance}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="ETB "
                      decimalScale={2}
                    />
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  align="center"
                  color="gray"
                  sx={{ mt: 1 }}
                >
                  The balance difference is calculated based on your current
                  transactions.
                </Typography>
              </Box>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
};

export default StoreTruthTrack;
