import React, { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router";
import { useLocation } from "react-router-dom";
import AddEmployee from "./markup/components/Admin/AddEmployeeForm/AddEmployeeForm";
import Employees from "./markup/pages/Admin/Employees/Employees";
import EditEmployee from "./markup/pages/Admin/Employees/EditEmployee";
import Branch from "./markup/pages/Admin/Branch/Branch";
import AgroInputCustomerDetails from "./markup/pages/ShowroomInputs/Agro/Customers/TransactionProfile";
import AgroInputCustomers from "./markup/pages/ShowroomInputs/Agro/Customers/CustomerList";
import AgroInputAddCustomer from "./markup/pages/ShowroomInputs/Agro/Customers/AddCustomer";
import AgroInputAddSaleProduct from "./markup/pages/ShowroomInputs/Agro/Products/SaleProduct";
import AgroInputAddProduct from "./markup/pages/ShowroomInputs/Agro/Products/AddProduct";
import AgroShowroomProducts from "./markup/pages/ShowroomInputs/Agro/Products/ShowroomProducts";
import AgroInputsoldProduct from "./markup/pages/ShowroomInputs/Agro/Products/SoldProducts";
import AgroCredit from "./markup/pages/Credit/AgroCustomerCredit";
// import PettyCashPayment from "./markup/pages/FinancialTransaction/PettyCashPayments/PettyCashPayment";
// import AddPettyCashPayment from "./markup/pages/FinancialTransaction/PettyCashPayments/AddPettyCashPayment";
import AddRecevierConfirmation from "./markup/pages/FinancialTransaction/ReceiverConfirmations/AddReceiverConfirmation";
import RecevierConfirmation from "./markup/pages/FinancialTransaction/ReceiverConfirmations/ReceiverConfirmation";
import AgroStoreExpiredProduct from "./markup/pages/Store/Agro/Products/ExpiredProducts";
import VetStoreExProduct from "./markup/pages/Store/Veterinary/Products/ExpiredProducts";
import Footer from "./markup/components/Footer/Footer";
import Header from "./markup/components/Header/Header";
import Home from "./markup/pages/Public/Home";
import "./assets/style/dashboard.css";
import "./assets/style/custom.css";
import "./assets/style/rootpage.css";
import "./assets/style/color.css";
import "./assets/style/responsive.css";
import Services from "./markup/pages/Public/Services";
import About from "./markup/pages/Public/About";
import Login from "./markup/pages/Public/Login";
import VerifyOTP from "./markup/pages/Public/VerifyOTP";
import Otps from "./markup/pages/Admin/otp/otps";

import Profile from "./markup/pages/Gen/Profile/Profile";
import AgroEmployeeReport from "./markup/pages/EmployeeReports/AgroEmployeeReport";
import VetEmployeeReport from "./markup/pages/EmployeeReports/VetEmployeeReport";
import AdminHelp from "./markup/pages/Gen/Help/AdminHelp";
import AIAssistant from "./markup/pages/Ai Assistant/ai-assistant";
import HelpRequest from "./markup/pages/Gen/Help/HelpRequest";
import EmployeeHelp from "./markup/pages/Gen/Help/EmployeeHelp";
import BackUp from "./markup/pages/Gen/Backups/BackUp";
import AgroExProduct from "./markup/pages/ShowroomInputs/Agro/Products/ExpiredProducts";
import AgroPendingProduct from "./markup/pages/ShowroomInputs/Agro/Products/PendingProduct";
import VetPendingProduct from "./markup/pages/ShowroomInputs/Veterinary/Products/PendingProduct";
import Report from "./markup/pages/ShowroomInputs/Agro/Reports/Report";
import VetReport from "./markup/pages/ShowroomInputs/Veterinary/Reports/Report";
import Survey from "./markup/pages/Gen/Survey/survey";
import AdminFeedback from "./markup/pages/Gen/Feedbacks/AdminFeedback";
import EmployeeFeedback from "./markup/pages/Gen/Feedbacks/EmployeeFeedback";
import Reminder from "./markup/pages/ShowroomInputs/Agro/Reports/Reminder";
import AgroShowroomCloneProducts from "./markup/pages/ShowroomInputs/Agro/Products/CloneProduct";
import AGOrders from "./markup/pages/ShowroomInputs/Agro/Customers/AGOrders";
import VTOrders from "./markup/pages/ShowroomInputs/Veterinary/Customers/VTOrders";
import AgroPettyPayment from "./markup/pages/ShowroomInputs/Agro/PettyPayment/PettyPayment";
import VetPettyPayment from "./markup/pages/ShowroomInputs/Veterinary/PettyPayment/PettyPayment";
import VetAddCustomer from "./markup/pages/ShowroomInputs/Veterinary/Customers/AddCustomer";
import VetCustomerList from "./markup/pages/ShowroomInputs/Veterinary/Customers/CustomerList";
import { useAuth } from "./Context/AuthContext";
import VetTransactionProfile from "./markup/pages/ShowroomInputs/Veterinary/Customers/TransactionProfile";
import VetShowroomCloneProducts from "./markup/pages/ShowroomInputs/Veterinary/Products/CloneProduct";
import VetAddProduct from "./markup/pages/ShowroomInputs/Veterinary/Products/AddProduct";
import VetShowroomProducts from "./markup/pages/ShowroomInputs/Veterinary/Products/ShowroomProducts";
import VetExProduct from "./markup/pages/ShowroomInputs/Veterinary/Products/ExpiredProducts";
import VetSoldProducts from "./markup/pages/ShowroomInputs/Veterinary/Products/SoldProducts";
import VetSallProduct from "./markup/pages/ShowroomInputs/Veterinary/Products/SaleProduct";
import VetCustomerCredit from "./markup/pages/Credit/VetCustomerCredit";
import VetReminder from "./markup/pages/ShowroomInputs/Veterinary/Reports/Reminder";
import AllEmployeeReport from "./markup/pages/Admin/Employees/AllEmployeesActivity";
import Sidebar from "./markup/components/Dashboard/sidebar";
import AllEmployeesActivity from "./markup/pages/Admin/Employees/AllEmployeesActivity";
import AllEmployeesReport from "./markup/pages/Admin/Employees/AllEmployeesReport";
import AgrotoreAvailableProducts from "./markup/pages/Store/Agro/Products/StoreAvailableProducts";
import AddAgroStoreProduct from "./markup/pages/Store/Agro/Products/AddProduct";
import VetSmallQuantityProducts from "./markup/pages/Store/Veterinary/Products/SmallQuantityProducts";
import AgroSmallQuantityProducts from "./markup/pages/Store/Agro/Products/SmallQuantityProducts";
import AgroSRSmallQuantityProducts from "./markup/pages/ShowroomInputs/Agro/Products/SmallQuantityProducts";
import AgroVTSmallQuantityProducts from "./markup/pages/ShowroomInputs/Veterinary/Products/SmallQuantityProducts";
import AgroOutedProducts from "./markup/pages/Store/Agro/Products/StoreOutedProduct";
import AgroOutProduct from "./markup/pages/Store/Agro/Products/OutProduct";
import VetOutProduct from "./markup/pages/Store/Veterinary/Products/OutProduct";
import AgroStoreCloneProducts from "./markup/pages/Store/Agro/Products/CloneProduct";
import VetStoreCloneProducts from "./markup/pages/Store/Veterinary/Products/CloneProduct";
import AgroStoreTruthTrack from "./markup/pages/Store/Agro/TruthTrack/TruthTrack";
import VetStoreTruthTrack from "./markup/pages/Store/Veterinary/TruthTrack/TruthTrack";
import AgroShowroomTruthTrack from "./markup/pages/ShowroomInputs/Agro/TruthTrack/TruthTrack";
import VetShowroomTruthTrack from "./markup/pages/ShowroomInputs/Veterinary/TruthTrack/TruthTrack";
import AgroAddedProductInvoice from "./markup/pages/Store/Agro/Products/AddedProductInvoice";
import AgroOutedProductInvoice from "./markup/pages/Store/Agro/Products/OutedProductInvoice";
import VetAddedProductInvoice from "./markup/pages/Store/Veterinary/Products/AddedProductInvoice";
import VetOutedProductInvoice from "./markup/pages/Store/Veterinary/Products/OutedProductInvoice";
import AddVetStoreProduct from "./markup/pages/Store/Veterinary/Products/AddProduct";
import VetstoreAvailableProducts from "./markup/pages/Store/Veterinary/Products/StoreAvailableProducts";
import VetOutedProducts from "./markup/pages/Store/Veterinary/Products/StoreOutedProduct";
import Contact from "./markup/pages/Public/Contact";
import Projects from "./markup/pages/Public/Projects";
import Partners from "./markup/pages/Public/Partners";
import Unauthorized from "./markup/pages/Public/Unauthorized";
import Loader from "./markup/components/Widgets/Loader";
import PrivateAuthRoute from "./markup/components/Auth/PrivateAuthRoute";
import Documentation from "./markup/pages/Gen/Doc/Documentation";
import employeeStatusService from "./services/employee_status.service";
import OnlineEmployees from "./markup/pages/OlineEmployees/OnlineEmployee";
import AGBarcodeScanner from "./markup/pages/Barcode/AG_Room";
import VTBarcodeScanner from "./markup/pages/Barcode/VT_Room";
import socket from "./util/socket";
import "./assets/style/custom.scss";
function App() {
  const [status, setStatus] = useState(null);
  const [onlineEmployees, setOnlineEmployees] = useState([]);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const employeeId = localStorage.getItem("EmployeeID");

    if (employeeId) {
      socket.emit("employeeOnline", employeeId);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          socket.emit("employeeOffline", employeeId);
        } else {
          socket.emit("employeeOnline", employeeId);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        socket.emit("employeeOffline", employeeId);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, []);

  useEffect(() => {
    const employeeId = localStorage.getItem("EmployeeID");
    const fetchInitialStatus = async () => {
      try {
        const response = await employeeStatusService.getEmployeeStatus(
          employeeId
        );
        setStatus(response.data);
      } catch (error) {
        console.error("Error fetching employee status:", error);
      }
    };

    fetchInitialStatus();

    socket.on("statusUpdate", (updatedStatus) => {
      if (updatedStatus.employee_id === employeeId) {
        setStatus(updatedStatus);
      }
    });

    return () => {
      socket.off("statusUpdate");
    };
  }, []);

  useEffect(() => {
    const handleOnlineEmployeesUpdate = (updatedOnlineEmployees) => {
      setOnlineEmployees(updatedOnlineEmployees);
    };

    socket.on("onlineEmployeesUpdate", handleOnlineEmployeesUpdate);

    return () => {
      socket.off("onlineEmployeesUpdate", handleOnlineEmployeesUpdate);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { employee, isLogged } = useAuth();

  const timerRef = useRef(null);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      const resetTimer = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(
          () => (window.location.href = "/"),
          120000
        );
      };

      // Add event listeners for user activity
      if (window.addEventListener("mousemove", resetTimer)) {
        console.log("Mouse move event listener added");
      }
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);
      window.addEventListener("click", resetTimer);
      window.addEventListener("scroll", resetTimer);

      // Start the timer initially
      resetTimer();

      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
        window.removeEventListener("click", resetTimer);
        window.removeEventListener("scroll", resetTimer);
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, [location.pathname]);

  const pathsToExclude = [
    "/",
    "/about",
    "/contact",
    "/verify-otp",
    "/login",
    "/dashboard/employee/survey",
    "/unauthorized",
    "/dashboard/documentation",
    // "/dashboard/product/barcode",
  ];

  // Check if the current location pathname should render the sidebar and navbar
  const shouldRenderSidebarAndNavbar = !pathsToExclude.includes(
    location.pathname
  );

  useEffect(() => {
    try {
      const isOutedProducts = JSON.parse(localStorage.getItem("hash_ops_data"));
      const isOProducts = localStorage.getItem("hash_ops_data");

      if (
        location.pathname ===
          "/dashboard/store/vet/outed-products/goods-note" &&
        isOProducts === "false"
      ) {
        window.history.back();
      } else if (
        location.pathname ===
          "/dashboard/store/agro/outed-products/goods-note" &&
        isOProducts === "false"
      ) {
        window.history.back();
      } else if (
        isLogged &&
        isOutedProducts &&
        isOutedProducts !== "false" &&
        isOutedProducts.Moreinfo.Service === "vet" &&
        location.pathname !== "/dashboard/store/vet/outed-products/goods-note"
      ) {
        window.location.href = "/dashboard/store/vet/outed-products/goods-note";
      } else if (
        isLogged &&
        isOutedProducts &&
        isOutedProducts !== "false" &&
        isOutedProducts.Moreinfo.Service === "agro" &&
        location.pathname !== "/dashboard/store/agro/outed-products/goods-note"
      ) {
        window.location.href =
          "/dashboard/store/agro/outed-products/goods-note";
      }
    } catch (error) {
      console.error("Error retrieving URL:", error);
    }
  }, [location.pathname]);

  useEffect(() => {
    try {
      const isAddedProducts = localStorage.getItem("hash_op_data");

      if (
        (location.pathname ===
          "/dashboard/store/agro/added-products/goods-note" &&
          isAddedProducts === "false") ||
        (location.pathname ===
          "/dashboard/store/vet/added-products/goods-note" &&
          isAddedProducts === "false")
      ) {
        window.history.back();
      }
    } catch (error) {
      console.error("Error retrieving URL:", error);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (employee) {
      document.title = `${employee?.employee_branch} AOSS CENTER - ${employee?.employee_first_name}`;
    }
  }, [employee]);

  useEffect(() => {
    // Simulate a data fetch
    setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds delay
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      {shouldRenderSidebarAndNavbar && (
        <>
          <PrivateAuthRoute roles={[4, 5, 2, 3, 1]}>
            <Sidebar status={status} />
          </PrivateAuthRoute>
        </>
      )}

      <Routes>
        <Route
          path=""
          element={
            <>
              <Header />
              <Home />
              <About />
              <Services />
              <Projects />
              <Partners />
              <Contact />
              <Footer />
            </>
          }
        />
        {/* <Route
          path="/dashboard/documentation"
          element={
            <>
              <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
                <Documentation />
              </PrivateAuthRoute>
            </>
          }
        /> */}
        <Route
          path="/login"
          element={
            <>
              <Header />
              <Login />
              <Footer />
            </>
          }
        />{" "}
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/verify-otp"
          element={
            <>
              <Header />
              <VerifyOTP />
              <Footer />
            </>
          }
        />
        <Route
          path="/dashboard/employee/personal/account"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <Profile />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/employee/online"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <OnlineEmployees employees={onlineEmployees} />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/product/barcode/vt"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <VTBarcodeScanner />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/product/barcode/ag"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <AGBarcodeScanner />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/admin/employees/all-activity"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5]}>
                <AllEmployeesActivity />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/admin/employees/all-report"
          element={
            <PrivateAuthRoute roles={[4, 5]}>
              <AllEmployeesReport />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/agro/petty-payment"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroPettyPayment />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/vet/petty-payment"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetPettyPayment />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/admin/otps"
          element={
            <>
              <PrivateAuthRoute roles={[5]}>
                <Otps />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/agro/customer/orders"
          element={
            <PrivateAuthRoute roles={[4, 5, 1]}>
              <AGOrders />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/vet/customer/orders"
          element={
            <PrivateAuthRoute roles={[4, 5, 2]}>
              <VTOrders />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/admin/feedbacks"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5]}>
                <AdminFeedback />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/agro/employee/reminder"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <Reminder />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/vet/employee/reminder"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetReminder />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/employee/feedbacks"
          element={
            <>
              <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
                <EmployeeFeedback />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/employee/survey"
          element={
            <>
              <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
                <Survey />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/backup"
          element={
            <>
              <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
                <BackUp />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/ai-assistant/:ConvID?"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <AIAssistant />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/help-request"
          element={
            <PrivateAuthRoute roles={[1, 2, 3, 4, 5]}>
              <HelpRequest />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/admin/help"
          element={
            <PrivateAuthRoute roles={[4, 5]}>
              <AdminHelp />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/employee/help"
          element={
            <PrivateAuthRoute roles={[4, 5, 2, 3, 1]}>
              <EmployeeHelp />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/employee/agro/my-reports"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroEmployeeReport />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/employee/vet/my-reports"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetEmployeeReport />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/showroom/agro/product"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroShowroomProducts />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/available-products"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 3]}>
                <AgrotoreAvailableProducts />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/available-products"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 3]}>
                <VetstoreAvailableProducts />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/agro/truth-track"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroShowroomTruthTrack />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/truth-track"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetShowroomTruthTrack />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/truth-track"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 3]}>
                <AgroStoreTruthTrack />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/truth-track"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 3]}>
                <VetStoreTruthTrack />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/agro/pending-products"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroPendingProduct />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/pending-products"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetPendingProduct />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/add-product"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AddAgroStoreProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/add-product"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AddVetStoreProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/outed-products"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AgroOutedProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/outed-products"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <VetOutedProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/added-products/goods-note"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AgroAddedProductInvoice />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/outed-products/goods-note"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AgroOutedProductInvoice />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/added-products/goods-note"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <VetAddedProductInvoice />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/outed-products/goods-note"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <VetOutedProductInvoice />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/agro/out-products"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <AgroOutProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/out-products"
          element={
            <PrivateAuthRoute roles={[4, 5, 3]}>
              <VetOutProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/product"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetShowroomProducts />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/agro/clone/product"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 1]}>
                <AgroShowroomCloneProducts />
              </PrivateAuthRoute>
            </>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/clone/product"
          element={
            <>
              <PrivateAuthRoute roles={[4, 5, 2]}>
                <VetShowroomCloneProducts />
              </PrivateAuthRoute>
            </>
          }
        />
        <Route
          path="/dashboard/admin/add-employee"
          element={
            <PrivateAuthRoute roles={[5]}>
              <AddEmployee />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/admin/employees"
          element={
            <PrivateAuthRoute roles={[5]}>
              <Employees />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/admin/edit-employee"
          element={
            <PrivateAuthRoute roles={[5]}>
              <EditEmployee />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/admin/branch"
          element={
            <PrivateAuthRoute roles={[5]}>
              <Branch />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/customer"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputCustomers />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/vet/customer"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetCustomerList />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/agro/customer/transaction-profile/:post_id"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputCustomerDetails />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/vet/customer/transaction-profile/:post_id"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetTransactionProfile />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/agro/add-customer"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputAddCustomer />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/vet/add-customer"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetAddCustomer />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/sell-product/:customerId"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputAddSaleProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/sell-product/:customerId"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetSallProduct />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/add-product"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputAddProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/agro/small-quantity-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroSRSmallQuantityProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/small-quantity-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <AgroVTSmallQuantityProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/add-product"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetAddProduct />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/sold-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroInputsoldProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/sold-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetSoldProducts />
            </PrivateAuthRoute>
          }
        />
        {/* <Route
          path="/dashboard/agro/sold-products/:productID"
          element={<EditSoldProduct />}
        /> */}
        <Route
          path="/dashboard/showroom/agro/customer/credits"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroCredit />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/customer/credits"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetCustomerCredit />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/expired-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <AgroExProduct />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/showroom/vet/expired-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetExProduct />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/agro/report"
          element={
            <PrivateAuthRoute roles={[5, 4, 1]}>
              <Report />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/showroom/vet/report"
          element={
            <PrivateAuthRoute roles={[5, 4, 2]}>
              <VetReport />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/store/agro/clone-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <AgroStoreCloneProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/clone-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <VetStoreCloneProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/expired-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <VetStoreExProduct />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/store/agro/expired-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <AgroStoreExpiredProduct />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/dashboard/store/agro/small-quantity-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <AgroSmallQuantityProducts />
            </PrivateAuthRoute>
          }
        />{" "}
        <Route
          path="/dashboard/store/vet/small-quantity-products"
          element={
            <PrivateAuthRoute roles={[5, 4, 3]}>
              <VetSmallQuantityProducts />
            </PrivateAuthRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
