import React, { useEffect, useState } from "react";
import "./Header.css";
import "./MobileMenu.css";
import logo from "../../../assets/images/footer-logo.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-scroll";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { Link as RouterLink } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../../Context/AuthContext";
import MobileMenu from "./MobileMenu";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const { employee, isLogged } = useAuth();
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else setShow(false);
    });
  }, []);

  return (
    <div className="minbg" id="Home">
      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
        employee={employee}
        isLogged={isLogged}
      />

      <div className="header-top  ">
        <div transition-style="in:wipe:left" className="container">
          <div className="row align-items-center">
            <div className="col-6 col-lg-2 ">
              <Link to="/" className="logo">
                <div className="">
                  <img
                    src={logo}
                    width="92"
                    alt="Image"
                    className="img-fluid bg-white rounded-5 p-1"
                  />
                </div>
              </Link>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <div className="quick-contact-icons d-flex align-items-center">
                <div className="icon align-self-center mt-1">
                  <span className="icon-room ">
                    <LocationOnIcon className="nav-icon" />
                  </span>
                </div>
                <div className="text">
                  <span className="h4 d-block">
                    Buee City Administration ETH
                  </span>
                  <span className="sub-text">
                    On the way to industrial college, 150m form the main road
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <div className="quick-contact-icons d-flex align-items-center">
                <div className="icon align-self-center mt-1">
                  <span className="icon-phone ">
                    <CallIcon className="nav-icon" />
                  </span>
                </div>
                <div className="text">
                  <span className="h4 d-block">+251911636597</span>
                  <span className="sub-text bold">
                    Dr. Hailu Tamrat (General Manager)
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-none d-lg-block">
              <div className="quick-contact-icons d-flex align-items-center">
                <div className="icon align-self-center align-items-center mt-1">
                  <span className="icon-envelope ">
                    <MailIcon className="nav-icon" />
                  </span>
                </div>
                <div className="text me-4">
                  <span className="h4 d-block">info@aosscenter.com</span>
                  <span className="sub-text">Contact us</span>
                </div>
                <span className="nav-social-icons d-none d-lg-block d-md-none ">
                  <YouTubeIcon className="nav-social-icon youtube me-1" />
                  <TelegramIcon className="nav-social-icon telegram  me-1" />
                  <WhatsAppIcon className="nav-social-icon whatsapp" />
                  {/* <MailIcon className="nav-social-icon  " /> */}
                </span>
              </div>
            </div>

            <div className="col-6 d-block  d-lg-none text-end">
              <Link
                className="d-inline-block d-lg-none text-black"
                onClick={toggleMobileMenu}
              >
                <span className="icon-menu ">
                  <MenuIcon className="text-black nav-icon" />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div id="" className={` ${show ? "is-sticky" : ""}`}>
          <div
            transition-style="in:wipe:right"
            className={`root-navbar ${
              show ? "scr" : ""
            } transition-style="out:wipe:down"py-2 js-sticky-header root-navbar-target d-none pl-0 d-lg-block shrink`}
            role="banner"
          >
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="mx-auto">
                  <nav className="root-navigation text-right" role="navigation">
                    <ul className="root-menu main-menu js-clone-nav mr-auto d-none pl-0 d-lg-block">
                      {currentUrl === "/login" ? (
                        <>
                          <li className="_lists_">
                            <RouterLink to="/" smooth={true} duration={500}>
                              Home
                            </RouterLink>
                          </li>
                          <li className="_lists_">
                            <RouterLink
                              to="/"
                              className="home-nav-link text-left"
                            >
                              About Us
                            </RouterLink>
                          </li>
                          <li className="_lists_">
                            <RouterLink
                              to="/"
                              className="home-nav-link text-left"
                            >
                              Our Services
                            </RouterLink>
                          </li>
                          <li className="_lists_">
                            <RouterLink
                              to="/"
                              className="home-nav-link text-left"
                            >
                              Projects
                            </RouterLink>
                          </li>
                          <li className="_lists_">
                            <RouterLink
                              to="/"
                              className="home-nav-link text-left"
                            >
                              Our Partners
                            </RouterLink>
                          </li>
                          <li className="_lists_">
                            <RouterLink
                              to="/"
                              smooth={true}
                              duration={500}
                              activeClass="active_nav"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              Contact
                            </RouterLink>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="_lists_">
                            <Link
                              to="Home"
                              offset={-100}
                              smooth={true}
                              duration={500}
                              activeClass="active"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              Home
                            </Link>
                          </li>
                          <li className="_lists_">
                            <Link
                              to="about"
                              smooth={true}
                              duration={500}
                              offset={-120}
                              activeClass="active"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              About Us
                            </Link>
                          </li>
                          <li className="_lists_">
                            <Link
                              to="services"
                              smooth={true}
                              duration={500}
                              offset={-130}
                              activeClass="active"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              Our Services
                            </Link>
                          </li>
                          <li className="_lists_">
                            <Link
                              to="projects"
                              smooth={true}
                              duration={500}
                              offset={-120}
                              activeClass="active"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              Projects
                            </Link>
                          </li>
                          <li className="_lists_">
                            <Link
                              to="partners"
                              smooth={true}
                              activeClass="active"
                              spy={true}
                              duration={500}
                              offset={-100}
                              className="home-nav-link text-left"
                            >
                              Our Partners
                            </Link>
                          </li>
                          <li className="_lists_">
                            <Link
                              to="contact"
                              smooth={true}
                              duration={500}
                              offset={-120}
                              activeClass="active"
                              spy={true}
                              className="home-nav-link text-left"
                            >
                              Contact
                            </Link>
                          </li>
                        </>
                      )}
                      {currentUrl === "/verify-otp" ? (
                        <li className="login_lists_">
                          <RouterLink
                            to="/verify-otp"
                            className="home-nav-link text-left"
                          >
                            <button id="button2_">Verify OTP</button>
                          </RouterLink>
                        </li>
                      ) : (
                        <li className="login_lists_">
                          {isLogged ? (
                            <RouterLink
                              to="/dashboard/employee/personal/account"
                              className="home-nav-link text-left"
                            >
                              <button className="gotodashboard">
                                <span>My Dashboard</span>
                              </button>
                            </RouterLink>
                          ) : (
                            <RouterLink
                              to="/login"
                              className="home-nav-link text-left"
                            >
                              <button id="button2_">Login</button>
                            </RouterLink>
                          )}
                        </li>
                      )}
                      <li className="login_lists_"></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
