// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

const Scan = async (ScannedData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(ScannedData),
  };
  try {
    const response = await fetch(
      `${api_url}/api/sales/auto/barcode-scanner`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }
    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }
    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};
const genAndGetBarcode = async (product_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/barcode/${product_id}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get product barcode: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error("Error getting product barcode:", error.message);
    throw error;
  }
};

const genAndGetBarcodeVT = async (product_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/barcode/vt/${product_id}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get product barcode: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error("Error getting product barcode:", error.message);
    throw error;
  }
};

const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

const productBarcode = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/product/barcodes`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get all products: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error getting all products:", error.message);
    throw error;
  }
};
const productBarcodeVt = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/product/barcodes/vt`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get all products: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error getting all products:", error.message);
    throw error;
  }
};
const ScannerService = {
  Scan,
  productBarcode,
  genAndGetBarcode,
  genAndGetBarcodeVT,

  productBarcodeVt,
};

export default ScannerService;
