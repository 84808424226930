import React, { useEffect, useState } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import otpService from "../../../../services/otp.service";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";

function OTP() {
  const [successMessage, setSuccessMessage] = useState("");
  const [otps, setOTPS] = useState([]);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await otpService.getOTPs();
        if (response && response.data && response.data.length !== 0) {
      
          setOTPS(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching OTPs:", error);
        setApiError(true);
      }
    };

    fetchData();
  }, []);

  return (
    <Dashboard>
      <Box sx={{ padding: 4 }}>
        {/* Success or Error Message */}
        {apiError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error fetching OTPs. Please try again later.
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}

        {/* OTP Table */}
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee Full Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>OTP</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {otps.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {apiError ? <CircularProgress /> : "No OTPs available."}
                  </TableCell>
                </TableRow>
              ) : (
                otps.map((otp) => (
                  <TableRow key={otp.otp_id}>
                    <TableCell>{otp.employee_full_name}</TableCell>
                    <TableCell>{otp.employee_phone}</TableCell>
                    <TableCell>{otp.otp_value}</TableCell>
                    <TableCell>{otp.otp_created_at}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dashboard>
  );
}

export default OTP;
