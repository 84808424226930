import React, { useState, useEffect } from "react";
import { useVetShowroomProducts } from "../../../../components/APIs/FetchAPIs";
import ShowroomProducts from "../../../../components/Products/ShowroomProducts";
import vetShowroomShowroomService from "../../../../../services/vet_showroom_product.service";
import BranchService from "../../../../../services/branch.service";

function VetShowroomProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const { vetproducts, isLoading, APIError, handleFetchData } =
    useVetShowroomProducts();
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response =
          await vetShowroomShowroomService.deleteVetShowroomProduct(ProductId);

        if (response.status === 200) {
          const responseData = await response.json(); // Parse response JSON

          setSuccessMessage(responseData.message); // Display server message
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();

    try {
      const response =
        await vetShowroomShowroomService.updateVetShowroomProduct(
          ProductId,
          updatedProductData
        );

      if (response.status === "success") {
        // Provide user feedback about success
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => setSuccessMessage(""), 3000);

        // Redirect after a delay
        handleFetchData();
        setEditingProduct(null);
      } else {
        // Handle other response statuses
        setError("Failed to update product. Please try again.");
        setTimeout(() => setError(""), 3000);
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <div>
      <ShowroomProducts
        products={vetproducts}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        handleFetchData={handleFetchData}
        service={"vet"}
        editingProduct={editingProduct}
        setEditingProduct={setEditingProduct}
      />
    </div>
  );
}

export default VetShowroomProducts;
