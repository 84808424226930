import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import EmployeeProfileView from "../upload/EmployeeProfileView";
import { useAuth } from "../../../Context/AuthContext";
import WeeklyAvalableWidget from "../Widgets/WeeklyAvalableWidget";
import Logo from "../../../assets/images/footer-logo.png";
import useSidebarItems from "./sidebarData";
import { Box, Typography } from "@mui/material";

function Sidebar({ status }) {
  const { employee } = useAuth();
  const SidebarItem = useSidebarItems();
  const [dropdownState, setDropdownState] = useState({});
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const storedVisibility = localStorage.getItem("sidebarVisible");
  const location = useLocation();
  useState(() => {
    const initialDropdownState = {};
    SidebarItem.forEach((item) => {
      if (item.dropdown) {
        initialDropdownState[item.text.toLowerCase()] = item.v || false;
      }
    });
    setDropdownState(initialDropdownState);
  }, []);

  const handleDropdownToggle = (dropdownName) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };
  const isActive = (link) => location.pathname === link;
  const handleDropdownToggleSubM = (dropdownName, subItem) => {

    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownName]: subItem.v ? true : !prevState[dropdownName],
    }));
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <div key={index} className=" navbar-nav w-100">
        {item.dropdown ? (
          <div className="nav-item dropdown">
            <Link
              to="#"
              className={`nav-link dropdown-toggle nav-item-div sidenav-links d-flex ${
                isActive(item.link) ? "active" : ""
              }`}
              onClick={() => handleDropdownToggle(item.text.toLowerCase())}
              aria-expanded={
                dropdownState[item.text.toLowerCase()] ? "true" : "false"
              }
            >
              <i className="me-2">{item.icon}</i>
              {item.text}
            </Link>

            <div
              className={`dropdown-menu ${
                dropdownState[item.text.toLowerCase()] ? "show" : ""
              } bg-transparent border-0`}
            >
              {item.dropdown.map((subItem, subIndex) =>
                subItem.submenu ? (
                  <>
                    <Link
                      to="#"
                      className="cta text-decoration-none d-block"
                      onClick={() =>
                        handleDropdownToggleSubM(
                          subItem.text.toLowerCase(),
                          subItem
                        )
                      }
                    >
                      <span className="fs-6 text-white font-monospace">
                        {subItem.text}
                      </span>
                    </Link>

                    <div
                      className={`dropdown-menu-links dropdown-menu text-center-- ${
                        dropdownState[subItem.text.toLowerCase()] ||
                        subItem.submenu.some((subItem) =>
                          isActive(subItem.link)
                        )
                          ? "show"
                          : ""
                      } bg-transparent border-0`}
                    >
                      {subItem.submenu.map((submenuItem, submenuIndex) => (
                        <Link
                          key={submenuIndex}
                          to={submenuItem.link}
                          className={`dropdown-item ${
                            isActive(submenuItem.link) ? "active" : ""
                          }`}
                        >
                          {submenuItem.text}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="dropdown-menu-links ">
                    <Link
                      to={subItem.link}
                      className={`dropdown-item ${
                        isActive(subItem.link) ? "active" : ""
                      }`}
                    >
                      {subItem.text}
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <Link
            to={item.link}
            className={`nav-item nav-link nav-item-div sidenav-links d-flex ${
              isActive(item.link) ? "itemWithIconActive active" : ""
            }`}
          >
            <i className="me-2">{item.icon}</i>
            {item.text}
          </Link>
        )}
      </div>
    ));
  };

  return (
    <div>
      <div
        className={` sidebar  bg-custom-d- pe-4 pb-3 ${
          !isSidebarVisible || storedVisibility !== "true"
            ? "open_ sidebar_"
            : ""
        }`}
      >
        <nav className="navbar  ">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: 2.1,
              mt: 1,
              mb: 1.4,
              fontSize: ".3rem",
              fontWeight: "bold",
              color: "white",
              borderRadius: 1,
              padding: "2px 16px",
            }}
          >
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                width: 40,
                height: 40,
                marginRight: 2,
                p: 0.3,
                bgcolor: "white",
                borderRadius: "50%",
              }}
            />

            <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
              {employee?.employee_branch} Aoss Center
            </Typography>
          </Box>
          <div className="pro-conteiner mb-3">
            <Link
              to="/dashboard/employee/personal/account"
              className="d-inline-block pro-r align-items-center justify-content-center ms-4 al text-decoration-none mb-2 text-center"
            >
              <div className="position-relative">
                <EmployeeProfileView
                  picstyle={{
                    borderRadius: "50%",
                    width: "95px",
                    height: "95px",
                  }}
                  w={90}
                />
              </div>
              <div className="ms-1 text-light text-center">
                <h6 className="mb-0">
                  {employee?.employee_first_name} {employee?.employee_last_name}
                </h6>
                <span className="text-sm-center text-secondary d-block txt-mysm">
                  {employee?.employee_email}
                </span>
                <span
                  className={`badge rounded-pill   ${
                    status?.status === "online" ? "bg-success" : "bg-primary"
                  }  `}
                >
                  {status?.status}
                </span>
              </div>
            </Link>
          </div>
          <div className="navbar-nav w-100">
            {renderMenuItems(SidebarItem)}

            <div
              className="card cdcard text-white  wkslcard  sidenav-links bg-custom-d- h-50"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {employee?.employee_role !== 3 && <WeeklyAvalableWidget />}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
