import React from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  SIunits,
  agrochemicalDrugTypes,
  veterinaryDrugTypes,
  AnimalFeed,
} from "../UnitsAndTypes/UnitsAndTypes";
import dayjs from "dayjs";

function ProductFormFields({
  productOptions,
  productData,
  handleDateChange,
  handleChange,
  handleSelectChange,
  handleCreateOption,
  customStyles,
  isProductCreated,
  setIsProductCreated,
  service,
  selectedService,
  type,
  handleProductSelect,
  selectedProduct
}) {
  const handleSelectChangeAndCheckCreated = (selectedOption) => {
    handleSelectChange(selectedOption, "product_name");
    handleProductSelect(selectedOption);
    setIsProductCreated(
      !productOptions.some((option) => option.value === selectedOption.value)
    );
    
  };


  const existProduct = {
    product_name: selectedProduct?.product_name,
    expiration_date: selectedProduct?.value?.expiration_date,
    product_type: selectedProduct?.value?.product_type,
    product_unit: selectedProduct?.value?.product_unit,
    product_quantity: selectedProduct?.value?.product_quantity,
    product_unit_price: selectedProduct?.value?.product_unit_price,
    usage: selectedProduct?.value?.usage,
  }

  return (
    <>
      <div className="mb-3">
        <CreatableSelect
          className="hight"
          styles={customStyles}
          classNamePrefix="select"
          name="product_name"
          onChange={handleSelectChangeAndCheckCreated}
          onCreateOption={(inputValue) => {
            handleCreateOption(inputValue, "product_name");
            setIsProductCreated(true);
          }}
          options={
            selectedService === "Animal-Feed" ? AnimalFeed : productOptions
          }
          value={
            selectedService === "Animal-Feed"
              ? AnimalFeed.find(
                  (type) => type.value === productData.product_name
                ) || null
              : productData.product_name
              ? {
                  value: productData.product_name,
                  label: productData.product_name,
                }
              : existProduct ? existProduct.product_name : null
          }
          placeholder="Search for a product..."
        />
      </div>
      <div className="form-floating mb-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="w-100 rounded-1 text-white bg-light border-black"
            isClearable={true}
            inputFormat="dd/MM/yyyy"
            name="expiration_date"
            defaultValue={dayjs(new Date())}
            value={productData.expiration_date}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-3">
        <Select
          options={
            service === "vet" ? veterinaryDrugTypes : agrochemicalDrugTypes
          }
          isDisabled={existProduct.product_type}
          placeholder={existProduct.product_type || "Product Type"}
          styles={customStyles}
          value={
            service === "vet"
              ? veterinaryDrugTypes.find(
                  (type) => type.value === productData.product_type
                ) || null
              : agrochemicalDrugTypes.find(
                  (type) => type.value === productData.product_type
                ) || null
          }
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, "product_type")
          }
        />
      </div>
      <div className="mb-3">
        <Select
          options={SIunits}
          placeholder={existProduct.product_unit || "Product SI unit"}
          styles={customStyles}
          value={
            SIunits.find((unit) => unit.value === productData.product_unit) ||
            selectedProduct?.value?.product_unit
          }
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, "product_unit")
          }
          isDisabled={existProduct.product_unit}

        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text">QTY</span>
        <input
          type="number"
          id="PQTY"
          className="form-control p-3"
          placeholder="Quantity"
          name="product_quantity"
          value={productData.product_quantity}
          onChange={handleChange}
         
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text">ETB</span>
        <input
          type="number"
          className="form-control p-3"
          id="Unitprice"
          placeholder="Price"
          name="product_unit_price"
          value={productData.product_unit_price}
          onChange={handleChange}
        />
        <span className="input-group-text">.00</span>
      </div>{" "}
      {type === "store" ? (
        <></>
      ) : (
        <>
          {isProductCreated && (
            <div className="input-group mb-3">
              <textarea
                rows="2"
                className="form-control p-3"
                id="usage"
                placeholder={`Please provide instructions on how to use the product in Amharic and English`}
                name="usage"
                value={productData.usage}
                onChange={handleChange}
                required={isProductCreated}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ProductFormFields;
