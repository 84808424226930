import React, { useState, useEffect } from "react";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";
import ShowroomProducts from "../../../../components/Products/ShowroomProducts";
import agroShowroomShowroomService from "../../../../../services/agro_showroom_product.service";
import BranchService from "../../../../../services/branch.service";
function AgroShowroomProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(null);
  const { agroproducts, isLoading, APIError, handleFetchData } =
    useAgroShowroomProducts();

  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response =
          await agroShowroomShowroomService.deleteAgroShowroomProduct(
            ProductId
          );

        if (response.status === 200) {
          const responseData = await response.json(); // Parse response JSON

          setSuccessMessage(responseData.message); // Display server message
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();

    try {
      const response =
        await agroShowroomShowroomService.updateAgroShowroomProduct(
          ProductId,
          updatedProductData
        );

      if (response.status === "success") {
        // Provide user feedback about success
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => setSuccessMessage(""), 3000);
        // Redirect after a delay
        handleFetchData();
        setEditingProduct(null);
      } else {
        setError("Failed to update product. Please try again.");
        setTimeout(() => setError(""), 3000);
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
      setTimeout(() => setError(""), 3000);
    }
  };

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      <ShowroomProducts
        products={agroproducts}
        Error={Error}
        handleFetchData={handleFetchData}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        editingProduct={editingProduct}
        setEditingProduct={setEditingProduct}
      />
    </div>
  );
}

export default AgroShowroomProducts;
