const api_url = process.env.REACT_APP_API_URL;

const getOTPs = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(`${api_url}/api/admin/otps`, requestOptions);
  return response.json();
};

const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

const otpService = {
  getOTPs,
};

export default otpService;
